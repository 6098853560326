import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { getRating } from '@/helpers/statusIncident.js';
import TicketItem from '@/components/request/elements/TicketItem/TicketItem.vue';
import TicketComments from '@/components/request/elements/TicketComments/TicketComments.vue';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import { showError } from '@/helpers/globalHelpers';
import RatingDialog from '@/components/request/elements/RatingDialog/RatingDialog.vue';
import ReassignUserDialog from '../../request/elements/ReassignUserDialog';
import UserConfirmDialog from '@/components/request/elements/AssignUserConfirmDialog/AssignUserConfirmDialog.vue';
import AddUserTicketDialog from '@/components/request/elements/AddUserTicketDialog/AddUserTicketDialog.vue';
import TicketDetailElement from '@/components/request/elements/TicketDetailElement/TicketDetailElement.vue';
import UserAdditionalDialog from '@/components/request/elements/UserAdditionalDialog/UserAdditionalDialog.vue';

export default {
	name: 'TicketDetail',
	data() {
		return {
			reassignUserDialog: false,
			userConfirmDialog: false,
			addUserDialog:false,
			additionalUsersDialog:false,
			source: 'request-detail',
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentsHome',
					},
				},
				{
					text: 'Solicitudes de Tickets',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'RequestList',
					},
				},
				{
					text: 'Detalle de Ticket',
					link: true,
					exact: true,
					disabled: true,
				},
			],
			statusList: [
				{ text: 'Solicitado', value: 'Solicitado' },
				{ text: 'Asignado', value: 'Asignado' },
				{ text: 'Resolución', value: 'Resolución' },
				{ text: 'Consulta', value: 'Consulta' },
				{ text: 'Cerrado', value: 'Cerrado' },
			],
			loading: false,
			filters: {
				q: null,
				page: 1,
				area: null,
				status: null,
				pagination: true,
				per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			},
			// mode: 'REQUEST', // TICKETSTARTED,REQUEST
			isRatingActive: false,
		};
	},

	created() {
		this.getRequestId();
		this.getResponsibles();
	},

	computed: {
		...mapState('ticket', ['ticket']),
		...mapState('auth', ['user','activities']),
		hasStartTicketPermission() {
			return this.$store.getters['auth/hasActivity'](
				'solicitudes',
				'detalleSolicitud',
				'iniciarTicket'
			);
		},
		hasCommentPermission() {
			return this.$store.getters['auth/hasActivity'](
				'solicitudes',
				'detalleSolicitud',
				'escribirComentarios'
			);
		},
		showSendComments() {
			if (this.ticket?.ticket_attention?.responsible_id?.id !== this.user?.id) {
				return false;
			} else {
				return true;
			}
		},

		isColumns() {
			return (
				this.ticket?.ticket_attention &&
				!['SOLICITADO', 'CANCELADO'].includes(this.ticket?.status)
			);
		},
		showDetailTicket() {
			const found = this.activities?.some(
				(activity) =>
					activity.module_name === 'solicitudes' &&
					activity.page_name === 'detalleSolicitud' &&
					activity.activity_name === 'MostrarSolicitudSuperAdmin'
			);
		
			const hasPermission = this.validateUserPermission(this.ticket, this.user.id);

			const isResponsible = this.ticket?.responsible_id === this.user?.id;
		
			return found || hasPermission || isResponsible;
		
		}

		// ticketDetails() {
		// 	const details = [
		// 		{
		// 			id: 'num_ticket',
		// 			label: 'Fecha de Registro:',
		// 			icon: 'mdi-calendar-range',
		// 			format: (value, item)=>{
		// 				return formatDate(ticket?.[value])
		// 			},
		// 		},
		// 	];
		// 	return details
		// },
	},

	methods: {
		getIncidentStatus,
		...mapActions('user', ['getResponsibles']),
		...mapActions('ticket', ['getTicketById', 'cleanTicket']),
		...mapActions('ticketAttention', ['createTicketAttention']),
		getRating,
		changeFilter() {},
		startTicket() {
			this.$swal({
				icon: 'question',
				title: 'Confirmación de Inicio',
				text: '¿Estás seguro de que deseas iniciar con este ticket?',
				showCancelButton: true,
				confirmButtonText: 'Sí, iniciar',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
			}).then(async (result) => {
				if (result.isConfirmed) {
					// Iniciar Ticket / Crear Ticket-Attention
					await this.initiateTicket();
					// this.mode = 'TICKETSTARTED';
				}
			});
		},

		async getRequestId() {
			try {
				this.loading = true;
				const id = this.$route.params.requestId;
				const {error} = await this.getTicketById(id);
				if(error) showError(error);
				this.loading = false;
			} catch (ex) {
				console.log(ex);
			}
		},

		async initiateTicket() {
			const payload = {
				//company_id: parseInt(localStorage.getItem('company_id')),
				ticket: this.$route.params.requestId,
			};
			const { ok, error } = await this.createTicketAttention(payload);
			if (ok) {
				this.getRequestId();
			} else {
				showError(error);
			}
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		downloadFile(filePath) {
			const fileName = filePath.split('/').pop();
			let a = document.createElement('a');
			a.href = filePath;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
		printSubtype(item) {
			return (
				item?.subtype?.classification?.area?.name +
				' / ' +
				item?.subtype?.classification?.name +
				' / ' +
				item?.subtype?.name
			);
		},
		printAssets(assets) {
			let assetsText = [];
			if (Array.isArray(assets)) {
				assets.forEach((asset) => {
					assetsText.push(asset?.name + ' ' + asset?.serial_number);
				});
			}
			return assetsText.join(', ');
		},

		setTicketStatus(value) {
			this.ticket.status = value;
		},

		getNamefile(url) {
			try {
				const urlParts = url.split('/');
				const fileNameWithTimestamp = urlParts[urlParts.length - 1];
				const fileNameParts = fileNameWithTimestamp.split('-');
				fileNameParts.shift();
				const fileName = fileNameParts.join('-');
				const filenameFinal = fileName.split('?')[0];
				return filenameFinal;
			} catch (error) {
				return 'EVIDENCIA';
			}
		},

		reassignUser() {
			//this.item = Object.assign({}, request);
			this.reassignUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		addUserConfirm() {
			this.userConfirmDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		addUser(){
			this.addUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		additionalUsers(){
			this.additionalUsersDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		
		hasPermission(structure, userId) {	   
			while (structure) {
				if (structure.responsible_id && structure.responsible_id.id === userId) {
					return true;
				}
				structure = structure.parent;
			}
			return false;
		},

		validateUserPermission(ticket, userId) {	
			if (ticket?.subtype && this.hasPermission(ticket?.subtype, userId)) {
				return true;
			}
		
			if (ticket?.subtype && ticket?.subtype?.classification) {
				const classification = ticket?.subtype?.classification;
				for (const subtype of classification?.subtypes) {
					if (this.hasPermission(subtype, userId)) {
						return true;
					}
				}
			}
			return false;
		},
	},
	beforeDestroy() {
		this.cleanTicket();
	},
	components: {
		UserConfirmDialog,
		TicketItem,
		TicketComments,
		RatingDialog,
		ReassignUserDialog,
		TicketDetailElement,
		AddUserTicketDialog,
		UserAdditionalDialog
	},
};
